import React from 'react'
import './Mobile-Table.scss'
import {
  Card,
} from 'react-bootstrap';
import {
  Link,
} from 'react-router-dom';
import StyledSelect from '../../../../components/Status';
import { MoreVertical } from 'react-feather';
import removeUndefinedWord from '../../../../helper/updateString';
import getImageUrl from '../../../../helper/checkImageFormat';

const MobileTable = ({ list, imageUrl, minutesAgo, timezone, getKey, handleChangeStatus, propertyStatus, address }) => {
  console.log({list})
  return (
    list.map((element, index) => {
      return (
        <Card className='mobile-table shadow' key={element._id}
        >
          <Link
            to={`/property/${element._id}`}
          >
            <div className='image-div'
            >
              <Card.Img
                variant="top"
                src={getImageUrl(element.primaryImage)}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = require('../../../../assests/images/1-placeholder.png');
                }}
                className='card-img'

              />

              {/* <span
                className={`text-uppercase properties-kit-status properties-${minutesAgo(
                  element.lastPing,
                )}`}
              >
                {minutesAgo(element.lastPing)}
              </span> */}
              <MoreVertical
                className='more-icon'
                height={'24'}
                width={'24'}
              />
            </div>
          </Link>
          <Card.Body className='p-2'>
            <div className=' d-flex justify-content-between align-items-center m-0'>
              <span className="tags">
                {element.classification}
              </span>
              <span className="body-xs">
                ${element.price}
              </span>
            </div>
            <div className='pt-2 d-flex flex-column'>
              <span className="body-s-bold">{element.address}</span>
            </div>
            <div className='d-flex gap-2 pt-2'>
              <span className="text-nowrap body-xs">
                {/* {element.features.rooms} BR */}
                <span className='body-xs-b'>{element.bedrooms && `${element.bedrooms}BR`}</span>
              </span>


              <span className="text-nowrap body-xs">
                {/* {element.features.bathrooms} BA */}
                <span className='body-xs-b'>{element.bathrooms && `${element.bathrooms}BA`}</span>
              </span>
            </div>
            <div className='d-flex justify-content-between align-items-end pt-3 body-xs'>
              {element.contacts ? element.contacts.length : 0} leads
              <StyledSelect
                status={
                  element.status
                }
                menuPortalTarget={document.body}
                values={[]}
                onChange={(e) => handleChangeStatus(e, element._id)}
                placeholder={element.status}
                options={propertyStatus}
                size="sm"
                className='status-td'
              />
            </div>
          </Card.Body>
        </Card>
      )
    }))
}

export default MobileTable